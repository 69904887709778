export const environment = {
  production: false,
  // api_url: 'https://localhost:44335',
  api_url: 'https://api-welspun.sparkleweb.in',
  // live link
  //  api_url: 'http://115.246.29.130:82',
  // api_url: 'https://localhost:7106',
  // api_url: 'https://weldatahub.welspun.com/api',
  // api_url: 'http://192.168.56.5:85/api',


};
