import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/services/master.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  userForm: FormGroup;
  isSubmitted: boolean = false;
  userID: string = '';
  userRoles: any;
  countryInfo: any;
  genderInfo: any;
  stateInfo: any;
  show: boolean;
  profileImage: string;
  bsConfig: Partial<BsDatepickerConfig>;
  projects: any = [];
  groupedProjects: any[] = [];
  selectedProjectIds: any[] = [];
  countryId: number = 0;
  token: any = localStorage.getItem('access_token');
  userId = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
  selectedItems: any = []; // To store the selected items

  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private usersService: UsersService,
    private masterService: MasterService) {
    this.userForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      address: [''],
      city: [''],
      genderId: [0],
      phone: [''],
      dob: [null, [Validators.required]],
      email: ['', [Validators.required]],
      profilephoto: [''],
      projectAssignment: [],
      rolesId: ['', [Validators.required]],
      countryID: [0],
      stateID: [0],
      isActive: [true],
    });

    this.bsConfig = {
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date(),
      isAnimated: true
    };

    this.getCountryWithGender();
  }

  ngOnInit() {
    this.getUserById(this.userId);
  }

  ngAfterViewInit() {
    $('.dateFocus').focus(function (e: any) {
      $(this).blur();
    });
  }

  // Method to handle project selection change
  selectionChanged(event: any): void {
    const selectedProjects = event.value; // Assuming event.value is an array of selected items
    // Extract and create an array of objects with "projectID" property
    const selectedProjectIds = selectedProjects
      .map((project: any) =>
        ({ projectID: project.projectID }));
    // Log the array of selected project IDs in the desired format
    this.userForm.get('projectAssignment')?.setValue(selectedProjectIds);
  }

  // Method to handle form submission
  onSubmit() {
    this.isSubmitted = true;
    if (this.userForm.invalid) {
      return;
    }
    else {
      if (this.userForm.value.dob) {
        const formattedDate = format(this.userForm.value.dob, 'yyyy-MM-dd');
        this.userForm.get('dob')?.setValue(formattedDate);
      }
      this.userForm.get('projectAssignment')?.setValue(this.selectedItems);
      this.usersService.updateUser(this.userForm.value)
        .subscribe((res) => {
          this.userForm.get('dob')?.setValue(new Date(this.userForm.value.dob));
          if (res.status == 'Success' || res.status == 'success') {
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.message);
          }
        }, (error) => {
          const errorLog = error.error;
          this.toastr.error(errorLog?.message);
          this.userForm.get('dob')?.setValue(new Date(this.userForm.value.dob));
        })
    }
  }

  // Method to handle form control errors
  public error = (control: string, error: string) => {
    return this.userForm.controls[control].hasError(error);
  }

  // Method to fetch country and gender information
  getCountryWithGender() {
    let queryParams = 'MASTERGENDER,MASTERCOUNTRY';
    this.masterService.getMasterData(queryParams, 0).subscribe((masterInfo: any) => {
      this.countryInfo = masterInfo.masterCountry;
      this.genderInfo = masterInfo.masterGender;
    })
  }

  // Method to handle selected country
  selectedCountry(event: any) {
    this.countryId = this.userForm.value.countryID;
    this.masterService.getMasterState(this.countryId).subscribe((stateInfo: any) => {
      this.stateInfo = stateInfo.data;
    })
  }

  // Method to handle file change
  onFileChange(target: any) {
    if (target.files.item(0).type === 'image/jpeg' ||
      target.files.item(0).type === 'image/png' ||
      target.files.item(0).type === 'image/jpg') {
      this.show = false;
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.profileImage = reader.result as string;
        this.userForm.get('profilephoto')?.setValue(this.profileImage);
      }
      reader.readAsDataURL(file)
    } else {
      this.show = true;
      this.profileImage = '';
      this.userForm.get('profilephoto')?.setValue('');
      target.value = '';
    }
  }

  // Method to fetch user information by ID
  getUserById(id: string) {
    this.usersService.getUserById(id).subscribe((res: any) => {
      const userInfo = res.data?.user;
      const roleId = res.data?.roleId;
      this.selectedItems = res.data?.projectAssignment;
      this.countryId = userInfo.countryID;
      this.profileImage = userInfo.profilephoto;
      if (userInfo.dob) {
        const dateObject = new Date(userInfo.dob);
        userInfo.dob = dateObject;
      }
      this.userForm = this.formBuilder.group({
        userId: [userInfo.id],
        firstName: [userInfo.firstName],
        lastName: [userInfo.lastName],
        address: [userInfo.address],
        city: [userInfo.city],
        genderId: [userInfo.genderId],
        phone: [userInfo.phone],
        dob: [userInfo.dob, [Validators.required]],
        email: [userInfo.email, [Validators.required]],
        profilephoto: [userInfo.profilephoto],
        rolesId: [roleId, [Validators.required]],
        countryID: [userInfo.countryID],
        stateID: [userInfo.stateID],
        projectAssignment: [],
        isActive: [userInfo.isActive],
      });
      this.selectedCountry(null)
    })

  }

}
