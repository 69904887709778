<div style="background:#fff">
  <div class="container-fluid">
    <div class="row no-gutter">
      <!-- The image half -->
      <div class="col-md-8 d-none d-md-flex bg-image"></div>
      <!-- The content half -->
      <div class="col-md-4 bg-light">
        <div class="login d-flex align-items-center py-5">
          <!-- Demo content-->
          <div class="container">
            <div class="row">
              <div class="col-lg-10 col-xl-9 mx-auto">
                <h3 class="display-4 text-center mb-5">
                  <img src="../assets/images/brand/logo-3.png" width="60%" alt="logo" class="m-auto">
                </h3>
                <p class="text-muted mb-4 text-center">Welcome to <span class="txt-blue"><u class="fw-bold">WEL
                      Data-HUB</u></span></p>
                <form [formGroup]="loginForm">
                  <div class="form-group mb-3">
                    <input id="inputEmail" type="email" formControlName="email" placeholder="Email address" autofocus=""
                      class="form-control border-start border-2 border-primary border-0 shadow-sm px-4">
                    <div *ngIf="isSubmitted && error('email','required')" class="text-danger">
                      Email address is required.
                    </div>
                    <div *ngIf="isSubmitted && !error('email','required') && error('email','pattern')"
                      class="text-danger">
                      Please enter valid email address.
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                      <input id="inputPassword" [type]="showPassword ? 'password' : 'text'" appNoSpace
                        formControlName="password" placeholder="Password"
                        class="form-control border-start border-2 border-primary  border-0 shadow-sm px-4 text-primary">
                      <a href="javascript:void(0)"
                        class="input-group-text bg-white text-muted border-start border-2 border-primary border-0 shadow-sm px-4"
                        (click)="togglePassword()">
                        <i class="zmdi " [ngClass]="showPassword ? 'zmdi-eye-off':'zmdi-eye'" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div *ngIf="isSubmitted && error('password','required')" class="text-danger">
                      Password is required.
                    </div>
                    <div *ngIf="isSubmitted && !error('password','required') && error('password','pattern')"
                      class="text-danger">
                      Password must contain 8-20 characters including letters, numbers & special characters.
                    </div>
                  </div>
                  <div class="txt-blue float-right cursor-pointer mt-1 mb-2"><a routerLink="/forgot-password">Forgot
                      password?</a></div>
                  <div class="text-center">
                    <button type="submit"
                      class="btn btn-primary border-0 bg-blue btn-block text-uppercase mb-2 shadow-sm"
                      (click)="onSubmit()">Sign in</button>
                  </div>
                </form>
              </div>
              <div class="text-center d-flex justify-content-center mt-4" style="font-size:11px;">
                <p>&copy; Maintained by <a href="javaScript:void(0);" class="font-italic text-muted">
                    <u>WTSL</u></a>, All rights reserved. </p>
              </div>
            </div>
          </div><!-- End -->

        </div>
      </div><!-- End -->

    </div>
  </div>
</div>
