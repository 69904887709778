import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SsoService } from 'src/app/services/sso.service';

@Component({
  selector: 'app-SSOLogin',
  templateUrl: './SSOLogin.component.html',
  styleUrls: ['./SSOLogin.component.css']
})
export class SSOLoginComponent implements OnInit {
  token: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ssoService: SsoService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    // Extracting token from query parameters
    this.activatedRoute.queryParams.subscribe((params: any) => {
      // Replace spaces with '+' and store in 'token'
      this.token = params.token ? params.token.replace(/ /g, "\+") : null;
    })

    // Checking if token is not null
    if (this.token != null) {
      // Saving the token to localStorage
      localStorage.setItem('access_token', JSON.stringify(this.token));
      
      // Setting a redirect route for after successful login
      sessionStorage.setItem('redirectTo', '/welcome-screen');
      
      // Navigating to the welcome-screen
      this.router.navigate(['/welcome-screen']);
    } else {
      // Navigating to the default route if the token is null
      this.router.navigate(['/']);
      
      // Clearing access_token in localStorage
      localStorage.setItem('access_token', "");
      
      // Displaying an error message using Toastr
      this.toastr.error("Please contact your administrator.");
    }
  }
}
