import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, throwError } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { CommonService } from "../services/common.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private commonService: CommonService,) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem("access_token");
    if (JSON.parse(token!)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(token!)}`,
        },
      });
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if ((error.status === 401)) {
            this.router.navigate(['/']);
            this.commonService.logout();
          }
          return throwError(error);
        }))
  }
}
