import { AfterViewInit, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css']
})
export class CommonTableComponent implements AfterViewInit {
  @Input() tableTitle: string;
  @Input() addPermission: boolean;
  @Input() columns: any[];
  @Input() data: any[];
  @Input() actions: any;
  @Output() onTableActionClick = new EventEmitter<any>();
  @Output() onPaginationChange = new EventEmitter<any>();
  @Output() onAddSearchInput = new EventEmitter<any>();
  @Input() routing: string;
  @Input() param: string;
  @Input() itemsPerPage: number;
  @Input() currentPage: number;
  @Input() totalItems: number;
  public columnSize: number;
  private toastr = inject(ToastrService)
  search = '';

  // After the view has been initialized, set the columnSize
  ngAfterViewInit() {
    this.columnSize = this.columns.length;
  }

  // Event handler for table action clicks
  onActionClick(action: string, data: any, tableName: string) {
    const actionObj = { action, data, tableName };
    this.onTableActionClick.emit(actionObj);
  }

  // Event handler for search input keyup
  onKeyup(event: any) {
    this.search = event.target.value;
  }

  // Event handler for search button click
  onSearch() {
    if (this.search) {
      this.onAddSearchInput.emit(this.search);
      this.onPaginationChange.emit(1);
    } else {
      // Display a warning message if the search text is empty
      this.toastr.warning('Search text is required');
    }
  }

  // Event handler for reset button click
  onReset() {
    this.search = '';
    this.onAddSearchInput.emit(this.search);
    this.onPaginationChange.emit(1);
  }

  // Event handler for pagination button click
  onPaginationClick(event: any) {
    this.onPaginationChange.emit(event);
  }
}
