import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPercentageOnly]'
})
export class PercentageOnlyDirective {

  private regex: RegExp = /^(100(\.0{1,2})?|[0-9]?[0-9](\.[0-9]{1,2})?)%?$/;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement.value + event.key;

    // Allow Backspace, Tab, End, Home, ArrowLeft, and ArrowRight keys
    if (
      ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'].includes(event.key) ||
      (event.key === 'a' && (event.ctrlKey || event.metaKey))
    ) {
      return;
    }

    if (!String(input).match(this.regex)) {
      event.preventDefault();
    }
  }
}
