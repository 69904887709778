import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appNoSpace]'
})
export class NoSpaceDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text/plain');
  
    // Remove spaces from the pasted text
    const sanitizedText = pastedText.replace(/\s/g, '');
  
    // Update the input value with the sanitized text
    document.execCommand('insertText', false, sanitizedText);
  
    event.preventDefault();
  }
}
