import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullOrPlaceholder'
})
export class NullOrPlaceholderPipe implements PipeTransform {
  transform(value: any, placeholder: string = '-'): any {
    return (value !== null && value !== '' && value !== ' ') ? value : placeholder;
  }
}
