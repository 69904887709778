<!-- ROW-1 OPEN -->
<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">{{editMode ? 'Edit':'Add'}} {{formTitle}}</h3>
            </div>
            <form [formGroup]="form">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>GRN No <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="GRN No"
                                    appNumbersOnly>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Invoice No <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="Invoice No"
                                    appNumbersOnly>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Supplier Name <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="Supplier Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>UOM <span class="star-red  d-none">*</span></label>
                                <select class="form-control" placeholder="Select Status" formControlName="">
                                    <option value="">Select Status</option>
                                    <option value="Achieved">Achieved</option>
                                    <option value="Rejected">Rejected
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Received Quantity <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="Supplier Name">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Rate(INR) <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="Rate(INR)"
                                    appNumbersOnly>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Rate(INR) <span class="star-red  d-none">*</span></label>
                                <input type="text" class="form-control" formControlName="" placeholder="Rate(INR)"
                                    appNumbersOnly>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Invoice Attachment</label>
                                <input type="file" accept="image/*" class="form-control"
                                    placeholder="Invoice Attachment" (change)="onFileChange($event.target)">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label for="datepicker">Date</label>
                                <div class="input-group">
                                    <input type="text" id="datepicker" autocomplete="off" placeholder="Date" #f
                                        class="form-control dateFocus" bsDatepicker formControlName=""
                                        [bsConfig]="bsConfig">
                                    <div class="input-group-append">
                                        <span class="input-group-text" (click)="f.click()">
                                            <i class="fe fe-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Type <span class="star-red  d-none">*</span></label>
                                <select class="form-control" placeholder="Select Status" formControlName="">
                                    <option value="">Select Type</option>
                                    <option value="Achieved">Achieved</option>
                                    <option value="Rejected">Rejected
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label>Location/SubContractor <span class="star-red  d-none">*</span></label>
                                <select class="form-control" placeholder="Select Status" formControlName="">
                                    <option value="">Select Location/SubContractor</option>
                                    <option value="Achieved">Achieved</option>
                                    <option value="Rejected">Rejected
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div class="card-footer text-end">
                        <button type="submit" (click)="onSubmit()" class="btn btn-primary m-1">{{editMode ? 'Update'
                            :'Save'}}</button>
                        <button (click)="goBack()" class="btn btn-cancel my-1">Cancel</button>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- ROW-1 CLOSED -->