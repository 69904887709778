import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectTypeService } from 'src/app/services/project-type.service';
import { MenuConstants } from '../staticmenu';

@Component({
  selector: 'app-project-type',
  templateUrl: './project-type.component.html',
  styleUrls: ['./project-type.component.css']
})
export class ProjectTypeComponent implements OnInit {
  menu: any;
  projects: any = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalItems: number = 0;
  private projectTypeService = inject(ProjectTypeService);
  dynamicStyles: any = ['#692d80', '#f0a551', '#cf0d1e'];
  search = '';

  constructor(private _router: Router,) { }

  ngOnInit() {
    this.getModuleInfo();
    this.getProject();
  }

  // Fetch the list of projects
  getProject() {
    this.projectTypeService.getProject(this.currentPage, this.itemsPerPage, this.search)
      .subscribe((res: any) => {
        this.projects = res.data;
      })
  }

  // Navigate to a specific project
  navigate(projectID: any) {
    localStorage.setItem('projectID', projectID);
    const href = this._router.url;
    const array = href.split("/");
    this._router.navigate([`/${array[1]}/${this.menu[0].url}`]);
  }

  // Fetch the module information based on the current route
  getModuleInfo() {
    const href = this._router.url;
    const array = href.split("/");
    if (array[1] == "project") {
      this.menu = MenuConstants.projectMenu;
    }
    else if (array[1] == "brc") {
      this.menu = MenuConstants.brcMenu;
    }
    else if (array[1] == "ncr") {
      this.menu = MenuConstants.ncrMenu;
    }
    else if (array[1] == "rfi") {
      this.menu = MenuConstants.rfiMenu;
    }
  }
}
