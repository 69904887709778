import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionService {

  getRolePermissionsURL = 'api/RolePermission/GetRolePermissions';
  saveRolePermissionsURL = 'api/RolePermission/SaveRolePermissions';

  constructor(private commonService: CommonService) { }
  private roleDataSubject = new BehaviorSubject<any>(null);
  roleData$ = this.roleDataSubject.asObservable();

  setRoleData(data: any) {
    this.roleDataSubject.next(data);
  }


  getRolePermissions(roleId: any, projectId: number) {
    let url = this.getRolePermissionsURL + '?roleId=' + roleId + '&projectId=' + projectId;
    return this.commonService.getAll(url, {});
  }

  create(permissions: any) {
    return this.commonService.post(this.saveRolePermissionsURL, permissions)
  }
}
