import { Injectable } from '@angular/core';
import { CommonService } from '../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  private getMasterDataByNameURL = 'api/Master/MasterDataByName';
  private getStateInfo = 'api/Master/MasterStateByCountryID';
  private GetAllTableListURL = 'api/Master/GetAllTableList';

  constructor(private commonService: CommonService) { }

  getMasterData(value: string = '', projectID: number) {
    return this.commonService.getAll(this.getMasterDataByNameURL + '?masterDataNames=' + value + '&projectID=' + projectID, {});
  }

  getMasterState(countryID: number) {
    return this.commonService.getAll(this.getStateInfo + '?countryID=' + countryID, {});
  }

  getAllTableList(){
    return this.commonService.getAll(this.GetAllTableListURL,{});
  }
  
}
