<div class="row mt-5">
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Edit Profile</h3>
      </div>
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>First Name</label>
                <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Gender</label>
                <select class="form-control form-select select2" placeholder="Select Gender" formControlName="genderId">
                  <option value="0">Select Gender</option>
                  <option *ngFor="let gender of genderInfo" [value]="gender.id">
                    {{gender.gender}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Phone</label>
                <input type="text" class="form-control" formControlName="phone" placeholder="Phone" appNumbersOnly
                  minlength="10" maxlength="10">
                <div *ngIf="isSubmitted && !error('phone','required') && error('phone','pattern')" class="text-danger">
                  Please enter valid phone number.
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>DOB <span class="star-red">*</span></label>
                <div class="input-group">
                  <input type="text" id="datepicker" autocomplete="off" #dob class="form-control dateFocus"
                    placeholder="DOB" bsDatepicker formControlName="dob" [bsConfig]="bsConfig">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="dob.click()">
                      <i class="fe fe-calendar"></i>
                    </span>
                  </div>
                </div>
                <div *ngIf="isSubmitted && error('dob','required')" class="text-danger">
                  DOB is required.
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" formControlName="email" placeholder="Email" [readOnly]="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Country</label>
                <select class="form-control form-select select2" placeholder="Select Country"
                  formControlName="countryID" (change)="selectedCountry($event)">
                  <option value="0">Select Country</option>
                  <option *ngFor="let countries of countryInfo" [value]="countries.id">
                    {{countries.countryName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>State</label>
                <select class="form-control form-select select2" placeholder="Select State" formControlName="stateID">
                  <option value="0">Select State</option>
                  <option *ngFor="let states of stateInfo" [value]="states.id">
                    {{states.stateName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>City</label>
                <input type="text" class="form-control" formControlName="city" placeholder="City">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Profile Photo</label>
                <input type="file" accept="image/*" class="form-control" placeholder="Profile Photo"
                  (change)="onFileChange($event.target)">
                <span *ngIf="show" class="text-danger">Please select image only</span>
              </div>
              <div class="form-group">
                <label></label>
                <div class="mt-2" *ngIf="profileImage && profileImage !== ''">
                  <img [src]="profileImage">
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label>Address</label>
                <textarea type="text" class="form-control" formControlName="address" placeholder="Address"
                  rows="2"></textarea>
              </div>
            </div>
          </div>
          <div class="row"></div>
          <div class="card-footer text-end">
            <button type="submit" class="btn btn-primary m-1">Save</button>
            <!-- <button (click)="goBack()" class="btn btn-cancel my-1">Cancel</button> -->
          </div>
        </div>
      </form>
    </div>
  </div>
