import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { MainContainerComponent } from './home/main-container/main-container.component';
import { AuthGuard } from './common/guard/authentication.guard';
import { AccountGuard } from './common/guard/accounts.guard';
import { WelcomeComponent } from './home/welcome-screen/welcome/welcome.component';
import { roleGuard } from './common/guard/role.guard';
import { ProfileComponent } from './home/profile/profile.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { SSOLoginComponent } from './account/SSOLogin/SSOLogin.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AccountGuard],
  }, {
    path: 'sso',
    component: SSOLoginComponent
  },
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'project', pathMatch: 'full' },

      {
        path: 'project',
        loadChildren: () => import('./modules/project.module').then((m) => m.ProjectModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'brc',
        loadChildren: () => import('./modules/brc.module').then((m) => m.BRCModule)
      },
      {
        path: 'ncr',
        loadChildren: () => import('./modules/ncr.module').then((m) => m.NCRModule)
      },
      {
        path: 'rfi',
        loadChildren: () => import('./modules/rfi.module').then((m) => m.RFIModule)
      },
      {
        path: 'subcontractor',
        loadChildren: () => import('./modules/sub-contractor.module').then((m) => m.SubContractorModule),
        canActivate: [roleGuard]
      },
      {
        path: 'project-types',
        loadChildren: () => import('./modules/project-type.module').then((m) => m.ProjectTypeModule),
        canActivate: [roleGuard]
      },
      {
        path: 'role',
        loadChildren: () => import('./modules/role.module').then((m) => m.RoleModule),
        canActivate: [roleGuard]
      },
      {
        path: 'project-permission',
        loadChildren: () => import('./modules/project-permission.module').then((m) => m.ProjectPermissionModule),
        canActivate: [roleGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users.module').then((m) => m.UserModule),
        canActivate: [roleGuard]
      },
      {
        path: 'esg',
        loadChildren: () => import('./modules/esg.module').then((m) => m.EsgModule)
      },
      {
        path: 'audit-log',
        loadChildren: () => import('./modules/audit-log.module').then((m) => m.AuditLogModule),
        canActivate: [roleGuard]
      },
      {
        path: 'safety',
        loadChildren: () => import('./modules/safety.module').then((m) => m.SafetyModule),
        canActivate: [roleGuard]
      },
      {
        path: 'welcome-screen',
        component: WelcomeComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
    ]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: '**', redirectTo: 'welcome-screen', pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
