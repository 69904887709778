import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { distinctUntilChanged, map } from "rxjs/operators";
import { LoginUser } from '../modal/common.modal';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _loadingStatus = new Subject<any>();
  public educationalTopic = new BehaviorSubject<any>(null);
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  constructor(private http: HttpClient) {
  }

  setTopicObject(element: any) {
    this.educationalTopic.next(element);
  }

  getTopicObject(): Observable<any> {
    return this.educationalTopic.asObservable();
  }

  private SytemInfoSubject = new BehaviorSubject<any>({});
  public sytemInfo = this.SytemInfoSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public loginUserSubject = new BehaviorSubject<LoginUser>({} as LoginUser);
  public projectPermissionSubject = new BehaviorSubject<any>({} as any);
  public loginUser = this.loginUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public loadingStateSubject = new Subject<boolean>();
  public loadingState = this.loadingStateSubject.asObservable();

  public currentLoginUserInfoSubject = new BehaviorSubject<any>(null);
  public currentLoginUserInfo = this.currentLoginUserInfoSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  getAll(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .get<any>(`${environment.api_url}/${url}`)
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }

  getById(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .get<any>(`${environment.api_url}/${url}`, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  deleteById(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .delete<any>(`${environment.api_url}/${url}`, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }

  post(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .post<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          this.setLoadingStatus(false)
          return res;
        })
      );
  }

  put(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .put<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          this.setLoadingStatus(false)
          return res;
        })
      );
  }

  getFiles(url: any) {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,

    });
    return this.http.get(url, { headers: headers, responseType: 'blob' }).pipe(map(
      (res: any) => {
        return res;
      }));
  }


  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    var token = localStorage.getItem('access_token') || "";
    let roleName = JSON.parse(window.atob(token.split('.')[1]))
    ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
    const returnObj = Object.assign({});
    const loginData = this.projectPermissionSubject.value;
    // if (loginData.userPermission) {
    const modules = loginData.modulePermissions || [],
      screens = loginData.screenPermissions || [],
      actions = loginData.actionPermissions || [],
      userRoleName = roleName,
      isAdminLogin = (userRoleName || "").toUpperCase() === "SUPERADMIN" || (userRoleName || "").toUpperCase() === "MASTERADMIN";

    const moduleobj = modules.find((obj: any) => obj.moduleKey == moduleName);
    const screenObj = screens.find(
      (obj: any) =>
        obj.screenKey == screenName &&
        obj.moduleId == (moduleobj && moduleobj.moduleId)
    );

    const actionPermissions =
      actions.filter(
        (obj: any) => obj.screenId == (screenObj && screenObj.screenId)
      ) || [];

    actionPermissions.forEach((obj: any) => {
      returnObj[obj.actionKey] = obj.permission || isAdminLogin;
    });
    //}
    return returnObj;
  }

  getCurrentLoginLocationId(): string {

    let locationId: string = "";
    const loginData: any = this.currentLoginUserInfoSubject.value;
    if (loginData) {
      locationId = loginData.currentLocationId || 0;
    }
    return locationId.toString();
  }

  getSystemIpAddress(): string {
    return this.SytemInfoSubject.value && this.SytemInfoSubject.value.ipAddress;
  }
  patch(url: any, data: any, isLoading: boolean = true): Observable<any> {
    const headers = new HttpHeaders({
      additionalHeaders: this.getAdditionalHeaders,
    });
    isLoading && this.loadingStateSubject.next(true);
    return this.http
      .patch<any>(`${environment.api_url}/${url}`, data, { headers: headers })
      .pipe(
        map((res) => {
          isLoading && this.loadingStateSubject.next(false);
          return res;
        })
      );
  }
  get getAdditionalHeaders(): string {
    const additionalHeaders = JSON.stringify({
      Offset: new Date().getTimezoneOffset().toString(),
      Timezone: calculateTimeZone(),
      IPAddress: this.getSystemIpAddress(),
      LocationID: this.getCurrentLoginLocationId(),
      BusinessToken: localStorage.getItem("business_token")
    });
    return additionalHeaders;
  }

  isValidFileType(fileName: any, fileType: any): boolean {
    // Create an object for all extension lists
    let extentionLists: any = { video: [], image: [], pdf: [], excel: [], xml: [] };
    let isValidType = false;
    extentionLists.video = ['m4v', 'avi', 'mpg', 'mp4'];
    extentionLists.image = ['jpg', 'jpeg', 'bmp', 'png', 'ico'];
    extentionLists.pdf = ['pdf'];
    extentionLists.excel = ['excel'];
    extentionLists.xml = ['xml'];
    extentionLists.csv = ['csv', 'xlsx'];

    //get the extension of the selected file.
    let fileExtension = fileName.split('.').pop().toLowerCase();
    isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
    return isValidType;
  };
  getLoadingStatus(): Observable<boolean> {
    return this._loadingStatus.asObservable();
  }
  setLoadingStatus(status: boolean) {
    this._loadingStatus.next(status);
  }

  parseStringToHTML(str: any) {
    var dom = document.createElement('div');
    dom.innerHTML = str;
    return dom.firstChild;

  }
  encryptValue(value: any, isEncrypt: boolean = true): any {
    let response: any;
    if (value != null && value != "") {
      let pwd = "HCPRODUCT#!_2018@";
      let bytes: any;
      if (isEncrypt) {
        //     bytes = utf8.encode(value.toString());
        //     response = base64.encode(bytes);
        //response = CryptoJS.AES.encrypt(JSON.stringify(value), pwd);
      } else {
        // bytes = base64.decode(value);
        //   response = utf8.decode(bytes);
        // const bytes = CryptoJS.AES.decrypt(value, pwd);
        // if (bytes.toString()) {
        //   response= JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // }
        //      response = CryptoJS.AES.decrypt(value, pwd);//.toString(CryptoJS.enc.Utf8);
      }
    }
    return response;
  }

  setAuth(user: LoginUser) {
    localStorage.setItem('access_token', JSON.stringify(user.token));
    // Set current user data into observable
    this.loginUserSubject.next(user);

    this.currentLoginUserInfoSubject.next({
      ...user
    })
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }
  setPermission(permission: any) {
    this.projectPermissionSubject.next(permission);
  }
  logout() {
    // remove user from local storage to log user out
    this.purgeAuth();
  }

  purgeAuth() {
    localStorage.removeItem('access_token');
    localStorage.clear();
    // Set current user to an empty object
    this.loginUserSubject.next({} as LoginUser);

    this.currentLoginUserInfoSubject.next(null);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }
}



function calculateTimeZone(dateInput?: Date): string {
  var dateObject = dateInput || new Date(),
    dateString = dateObject + "",
    tzAbbr: any =
      // Works for the majority of modern browsers
      dateString.match(/\(([^\)]+)\)$/) ||
      // IE outputs date strings in a different format:
      dateString.match(/([A-Z]+) [\d]{4}$/);

  if (tzAbbr) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    tzAbbr = tzAbbr[1];
  }
  return tzAbbr;
}

export function noWhitespaceValidator(control: any) {
  const value = control.value;
  if (value != null && value.split(' ').join('').length === 0) {
    return { 'whitespace': true, 'message': 'Please do not add white space' };
  }
  return null;
}



