import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const AuthGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  if (localStorage.getItem('access_token')) {
    return true //this.commonService.isAuthenticated.pipe(take(1));
  }
  const queryParams = {};

  let redirectUrl = '';
  if (sessionStorage.getItem('redirectTo')) {
    redirectUrl = sessionStorage.getItem('redirectTo') || '';
    sessionStorage.clear();
  }
  router.navigate([redirectUrl], { queryParams });
  return false;
};
