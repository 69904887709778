import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const roleGuard: CanActivateFn = (route, state) => {
  const token: any = localStorage.getItem('access_token');
  const router = inject(Router);
  if (token == null) {
    router.navigate(['/']);
    localStorage.clear();
    return false;
  }
  const role = JSON.parse(window.atob(token.split('.')[1]))["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];

  if (role === 'SuperAdmin' || role === 'MasterAdmin') {
    return true;
  } else {
    router.navigate(['welcome-screen']);
    return false;
  }
};
