
<!-- PAGE -->
<div class="page">
  <div class="">

    <!-- Theme-Layout -->

    <!-- CONTAINER OPEN -->
    <div class="col col-login mx-auto">
      <div class="text-center">
        <a href="javaScript:0;"><img src="../assets/images/brand/logo-white.png" class="header-brand-img m-0"
            alt=""></a>
      </div>
    </div>

    <!-- CONTAINER OPEN -->
    <div class="container-login100">
      <div class="wrap-login100 w-25 p-6">
        <form class="login100-form validate-form" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <span class="login100-form-title pb-5">
            Reset Password
          </span>

          <div class="wrap-input100 validate-input input-group" id="Password-toggle">
            <input [type]="showPassword['newPassword'] ? 'text' : 'password'" class="input100 ms-0 form-control"
              placeholder="New Password" formControlName="newPassword" appNoSpace>
            <a href="javascript:void(0)" class="input-group-text bg-white text-muted"
              (click)="togglePassword('newPassword')">
              <i class="zmdi " [ngClass]="showPassword['newPassword'] ? 'zmdi-eye':'zmdi-eye-off'"
                aria-hidden="true"></i>
            </a>
          </div>
          <div *ngIf="isSubmitted && (error('newPassword', 'required') || error('newPassword', 'whitespace'))"
            class="text-danger">
            New Password is required.
          </div>
          <div *ngIf="isSubmitted && error('newPassword', 'pattern')" class="text-danger">
            Password should have Minimum 8 Characters atleast 1 uppercase 1 lowercase 1
            special
            character 1 number.
          </div>

          <div class="wrap-input100 validate-input input-group" data-bs-validate="Valid email is required: ex@abc.xyz">
            <input [type]="showPassword['confirmPassword'] ? 'text' : 'password'" class="input100 ms-0 form-control"
              placeholder="Confirm Password" formControlName="confirmPassword" appNoSpace>
            <a href="javascript:void(0)" class="input-group-text bg-white text-muted"
              (click)="togglePassword('confirmPassword')">
              <i class="zmdi " [ngClass]="showPassword['confirmPassword'] ? 'zmdi-eye':'zmdi-eye-off'"
                aria-hidden="true"></i>
            </a>
          </div>
          <div *ngIf="isSubmitted && (error('confirmPassword', 'required') || error('confirmPassword', 'whitespace'))"
            class="text-danger mb-1">
            Confirm Password is required.
          </div>
          <div *ngIf="isSubmitted && error('confirmPassword', 'confirmedValidator')" class="text-danger mb-1">
            Confirm Password must be the same as the New Password.
          </div>

          <div class="submit">
            <button type="submit" class="btn btn-primary d-grid w-100">Send</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
<!--END PAGE -->
