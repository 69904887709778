import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'src/app/common/services/common.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  token: any;
  email: any;
  resetPasswordForm: FormGroup;
  accountService = inject(AccountService);
  toastr = inject(ToastrService);
  isSubmitted = false;
  showPassword: any = {
    newPassword: false,
    confirmPassword: false
  };

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    // Initializing the reset password form with validation rules
    this.resetPasswordForm = this.formBuilder.group({
      email: [''],
      token: [''],
      newPassword: ['', [Validators.required, Validators.pattern(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'
      ), noWhitespaceValidator]],
      confirmPassword: ['', [Validators.required]]
    },
    {
      // Custom validator to check if new and confirm passwords match
      validators: [ConfirmedValidator('newPassword', 'confirmPassword')]
    });
  }

  ngOnInit(): void {
    // Extracting email and token from query parameters
    this.route.queryParams.subscribe((params: any) => {
      this.email = params.email;
      this.token = params.token.replace(/ /g, "\+");
    })
  }

  // Function to check if a form control has a specific error
  public error = (control: string, error: string) => {
    return this.resetPasswordForm.controls[control].hasError(error);
  }

  // Function to toggle the visibility of the password
  togglePassword(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  // Function to handle form submission
  onSubmit() {
    this.isSubmitted = true;

    // Setting email and token values
    this.resetPasswordForm.get('email')?.setValue(this.email);
    this.resetPasswordForm.get('token')?.setValue(this.token);

    // Stop here if the form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      // Call the resetPassword API
      this.accountService.resetPassword(this.resetPasswordForm.value)
        .subscribe((res) => {
          if (res.status == 'Success' || res.status == 'success') {
            this.toastr.success(res.message);
            this.goBack()
          }
          else {
            this.toastr.error(res.message);
          }
        }, (error) => {
          const errorLog = error.error;
          this.toastr.error(errorLog?.message);
        })
    }
  }

  // Function to navigate back to the login page
  goBack() {
    this.router.navigate(['login']);
  }
}

// Custom validator function to check if new and confirm passwords match
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
