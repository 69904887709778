import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from 'src/app/account/login/login.component';
import { MainContainerComponent } from 'src/app/home/main-container/main-container.component';
import { SubSidebarComponent } from 'src/app/layout/sub-sidebar/sub-sidebar.component';
import { NumbersOnlyDirective } from '../../directive/numbers-only.directive';
import { PercentageOnlyDirective } from '../../directive/percentage-only.directive';
import { ProjectTypeComponent } from 'src/app/layout/project-type/project-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { FooterComponent } from 'src/app/layout/footer/footer.component';
import { SidebarComponent } from 'src/app/layout/sidebar/sidebar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { DecimalFormatDirective } from '../../directive/decimal.directive';
import { CommonTableComponent } from '../../common-table/common-table.component';
import { CommonFormComponent } from '../../common-form/common-form.component';
defineLocale('en-gb', enGbLocale);
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { FormatDatePipe } from '../../pipes/formatdate.pipe';
import { NullOrPlaceholderPipe } from '../../pipes/nullOrPlaceholderPipe.pipe';
import { ChangePasswordComponent } from 'src/app/account/change-password/change-password.component';
import { ForgotPasswordComponent } from 'src/app/account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from 'src/app/account/reset-password/reset-password.component';
import { NoSpaceDirective } from '../../directive/whitespace.directive';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SSOLoginComponent } from 'src/app/account/SSOLogin/SSOLogin.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    SelectDropDownModule,
    MatTooltipModule
  ],
  declarations: [
    // components
    LoginComponent,
    SSOLoginComponent,
    MainContainerComponent,
    SubSidebarComponent,
    ProjectTypeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CommonTableComponent,
    CommonFormComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,

    // directives
    NumbersOnlyDirective,
    PercentageOnlyDirective,
    DecimalFormatDirective,
    NoSpaceDirective,

    // pipes
    FormatDatePipe,
    NullOrPlaceholderPipe,
    TruncatePipe,
  ],
  exports: [
    // modules
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    BsDatepickerModule,
    SelectDropDownModule,
    CommonFormComponent,

    // components
    LoginComponent,
    SSOLoginComponent,
    MainContainerComponent,
    SubSidebarComponent,
    ProjectTypeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CommonTableComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,

    // directives
    NumbersOnlyDirective,
    PercentageOnlyDirective,
    DecimalFormatDirective,
    NoSpaceDirective,


    // pipes
    NullOrPlaceholderPipe,
    FormatDatePipe,
    TruncatePipe
  ]
})
export class SharedModule { }
