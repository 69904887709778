import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDecimalFormat]'
})
export class DecimalFormatDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('blur') onBlur() {
    const inputValue = this.el.nativeElement.value;
    const formattedValue = parseFloat(inputValue).toFixed(2); // Format to two decimal places
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }
}
