<div class="app-header header sticky">
  <div class="container-fluid main-container">
    <div class="d-flex">
      <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="javascript:void(0)"></a>
      <!-- sidebar-toggle-->
      <a class="logo-horizontal " href="index.html">
        <img src="../assets/images/brand/logo.png" class="header-brand-img desktop-logo logosize" alt="logo">
        <img src="../assets/images/brand/logo-3.png" class="header-brand-img light-logo1 logosize" alt="logo">
      </a>
      <!-- LOGO -->

      <div class="main-header-center ms-3 d-none d-lg-block">
        <input type="text" class="form-control d-none" id="typehead" placeholder="Search for results..."
          autocomplete="off">
        <button class="btn px-0 pt-2 d-none"><i class="fe fe-search" aria-hidden="true"></i></button>
      </div>
      <div class="navbar-container">
        <ul class="nav1 br-7 me-2 bg-transparent">
          <ul class="nav">
            <ng-container *ngFor="let item of menu; let i = index">
              <li class="nav-item m-2" *ngIf="item.submenu && !item.submenu.length">
                <a class="nav-link px-4 py-2" [routerLink]="[item.navigationLink]" [routerLinkActive]="['active']"
                  (click)="navigateToDashboard()">{{item.moduleName}}</a>
              </li>
              <li class="nav-item dropdown m-2" *ngIf="item.submenu && item.submenu.length">
                <a class="nav-link dropdown-toggle px-4 py-2" role="button" aria-haspopup="true" aria-expanded="false"
                  (mouseout)="onMouseOut()">{{item.moduleName}}</a>
                <div class="dropdown-menu" *ngIf="item.moduleName != 'ESG'">
                  <ng-container *ngFor="let subItem of item.submenu">
                    <a class="dropdown-item" [routerLink]="[subItem.navigationLink]"
                      [routerLinkActive]="['active']">{{subItem.screenName}}</a>
                  </ng-container>
                </div>
                <div class="dropdown-menu" *ngIf="item.moduleName == 'ESG'">
                  <div class="esgdiv" *ngIf="showEmissionsTitle(item.submenu)">
                    <h3 class="esgh3" >EMISSIONS</h3>
                  </div>
                  <ng-container *ngFor="let subItem of item.submenu">
                    <ng-container *ngIf="subItem.type == 'EMISSIONS'">
                      <a class="dropdown-item" [routerLink]="[subItem.navigationLink]"
                        [routerLinkActive]="['active']">{{subItem.screenName}}</a>
                    </ng-container>
                  </ng-container>
                  <div class="esgdiv" *ngIf="showWaterTitle(item.submenu)">
                    <h3 class="esgh3" >WATER</h3>
                  </div>
                  <ng-container *ngFor="let subItem of item.submenu">
                    <ng-container *ngIf="subItem.type == 'WATER'">
                      <a class="dropdown-item" [routerLink]="[subItem.navigationLink]"
                        [routerLinkActive]="['active']">{{subItem.screenName}}</a>
                    </ng-container>
                  </ng-container>
                </div>
              </li>
            </ng-container>
          </ul>
        </ul>
      </div>
   
      <div class="d-flex order-lg-2 ms-auto header-right-icons">
        <div class="dropdown d-none">
          <a href="javascript:void(0)" class="nav-link icon" data-bs-toggle="dropdown">
            <i class="fe fe-search"></i>
          </a>
          <div class="dropdown-menu header-search dropdown-menu-start">
            <div class="input-group w-100 p-2">
              <input type="text" class="form-control" placeholder="Search....">
              <div class="input-group-text btn btn-primary">
                <i class="fe fe-search" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- SEARCH -->
        <button class="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon fe fe-more-vertical"></span>
        </button>


        <div class="navbar navbar-collapse responsive-navbar p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
            <div class="d-flex order-lg-2">


              <!-- COUNTRY -->
              <div class="d-flex country">

             
                <a class="nav-link icon theme-layout nav-link-bg layout-setting d-none" (click)="changeTheme()">
                  <span class="dark-layout"><i class="fe fe-moon"></i></span>
                  <span class="light-layout"><i class="fe fe-sun"></i></span>
                </a>
              </div>
              <!-- Theme-Layout -->

              <!-- CART -->
              <div class="dropdown d-flex">
                <a class="nav-link icon full-screen-link nav-link-bg">
                  <i class="fe fe-minimize fullscreen-button"></i>
                </a>
              </div>
            
              <!-- NOTIFICATIONS -->
              <!-- SIDE-MENU -->
              <div class="dropdown d-flex profile-1">
                <a href="javascript:void(0)" data-bs-toggle="dropdown" class="nav-link leading-none d-flex">
                  <img src="../assets/images/users/user.png" alt="profile-user"
                    class="avatar  profile-user brround cover-image" *ngIf="!userInfo.profilephoto">
                  <img src="{{userInfo?.profilephoto}}" alt="profile-user"
                    class="avatar  profile-user brround cover-image" *ngIf="userInfo?.profilephoto">
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <div class="drop-heading">
                    <div class="text-center">
                      <h5 class="text-dark mb-0 fs-14 fw-semibold text-capitalize">{{userInfo.firstName}}
                        {{userInfo.lastName}}</h5>
                      <small class="text-muted">{{role}}</small>
                    </div>
                  </div>
                  <div class="dropdown-divider m-0"></div>
                  <a class="dropdown-item cursor-pointer">
                    <i class="dropdown-icon fe fe-user"></i> Profile
                  </a>
                  <a class="dropdown-item cursor-pointer" routerLink="change-password">
                    <i class="dropdown-icon fe fe-lock"></i> Change Password
                  </a>
                  <a class="dropdown-item cursor-pointer" (click)="logout()">
                    <i class="dropdown-icon fe fe-alert-circle"></i> Sign out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
