<div class="sticky">
  <div class="app-sidebar__overlay" data-bs-toggle="sidebar"></div>
  <div class="app-sidebar">
    <div class="side-header">
      <a class="header-brand1" href="index.html">
        <img src="../assets/images/brand/logo.png" class="header-brand-img desktop-logo logosize" alt="logo">
        <img src="../assets/images/brand/logo-1.png" class="header-brand-img toggle-logo logosize" alt="logo">
        <img src="../assets/images/brand/logo-2.png" class="header-brand-img light-logo logosize" alt="logo">
        <img src="../assets/images/brand/logo-3.png" class="header-brand-img light-logo1 logosize" alt="logo">
      </a>
      <!-- LOGO -->
    </div>
    <div class="main-sidemenu">
      <div class="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
          height="24" viewBox="0 0 24 24">
          <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
        </svg></div>
      <ul class="side-menu">
        <li class="sub-category">
        </li>

        <li class="sub-category">
          <h3>Business Unit</h3>
        </li>
        <li class="slide" [ngClass]="{'is-expanded':transportationToggleSide}" *ngIf="hasTransportationProjects()">
          <a class="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)"
            (click)="onToggle('Transportation')">
            <i class="side-menu__icon fe fe-truck"></i>
            <span class="side-menu__label">Transportation</span>
            <i class="angle fe fe-chevron-right"></i>
          </a>
          <ul class="slide-menu" *ngFor="let project of projects; let i = index">
            
            <li *ngIf="project?.type == 'Transportation'"><a href="javascript:void(0)" class="slide-item"
                (click)="navigate(project)">
                {{project.projectName}}</a>
            </li>
          </ul>
        </li>
        <li class="slide" [ngClass]="{'is-expanded':waterToggleSide}" *ngIf="hasWaterProjects()">
          <a class="side-menu__item" data-bs-toggle="slide" href="javascript:void(0)" (click)="onToggle('Waters')">
            <i class="side-menu__icon fe fe-droplet"></i><span class="side-menu__label">Waters</span>
            <i class="angle fe fe-chevron-right"></i>
          </a>
          <ul class="slide-menu" *ngFor="let project of projects; let i = index">
            
            <li *ngIf="project?.type == 'Water'"><a href="javascript:void(0)" class="slide-item"
                (click)="navigate(project)">
                {{project.projectName}}</a>
            </li>
          </ul>
        </li>



        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/subcontractor"><i
              class="side-menu__icon fe fe-users"></i><span class="side-menu__label">Subcontractor</span></a>
        </li>
        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/project-types"><i
              class="side-menu__icon fe fe-layers"></i><span class="side-menu__label">New Project</span></a>
        </li>
        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/users"><i
              class="side-menu__icon fe fe-user"></i><span class="side-menu__label">User Management</span></a>
        </li>
        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/role"><i
              class="side-menu__icon fe fe-award"></i> <span class="side-menu__label">Role </span></a>
        </li>
        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/project-permission"><i
              class="side-menu__icon fe fe-check-square"></i><span class="side-menu__label">Project
              Permission</span></a>
        </li>
        <li class="slide" *ngIf="role === 'SuperAdmin' || role === 'MasterAdmin'">
          <a class="side-menu__item has-link" data-bs-toggle="slide" routerLink="/audit-log"><i
              class="side-menu__icon fe fe-file-text"></i><span class="side-menu__label">Audit
              Log</span></a>
        </li>

      </ul>
      <div class="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
          height="24" viewBox="0 0 24 24">
          <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
        </svg></div>
    </div>
  </div>
  <!--/APP-SIDEBAR-->
</div>