import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private addUpdateShortfallURL = 'api/Shortfall/AddUpdateShortfall';
  private getShortfallBySubcontractorIdURL = 'api/Shortfall/GetShortfallBySubcontractorId';
  private getData = 'api/Account/GetRoleList';
  private getByIdUserURL = 'api/Account/GetByIdUser';
  private changePasswordURL = 'api/Account/ChangePassword';
  private forgotPasswordURL = 'api/Account/ForgotPassword?email=';
  private resetPasswordURL = 'api/Account/ResetPassword';
  private addUpdateRoleURL = 'api/Account/AddUpdateRole';
  private deleteRoleURL = 'api/Account/DeleteRole?roleId=';
  private getRoleByIdURL = 'api/Account/GetRoleById?roleId='

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
  ) { }

  // Add or update shortfall data
  AddUpdateShortfall(shortfall: any) {
    return this.commonService.post(this.addUpdateShortfallURL, shortfall);
  }
  // Get project data with pagination
  getProject(PageNumber: number, PageSize: number) {
    return this.commonService.getAll(this.getData + '?PageNumber=' + PageNumber + '&PageSize=' + PageSize, {});
  }

  // Get daily achieved data by ID
  getDailyAchievedById(dailyAchievedId: number) {
    return this.commonService.getById(this.getShortfallBySubcontractorIdURL + '?DailyAchievedId=' + dailyAchievedId, {});
  }

  // User login with authentication
  login(postData: any) {
    const headers = new HttpHeaders({
      'businessToken': localStorage.getItem('business_token') || [],
    });
    return this.http.post<any>(`${environment.api_url}/api/Account/Login`, postData, { headers: headers })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        if (response && response.token) {
          this.commonService.setAuth(response);
        } else if (response && response.data) {
        }
        return response;
      }));
  }

  // Get user data by user ID
  getByIdUser(userId: string) {
    return this.commonService.getById(this.getByIdUserURL + '?userId=' + userId, {});
  }

  // Change user password
  changePassword(data: any) {
    return this.commonService.post(this.changePasswordURL, data);
  }

  // Initiate forgot password process
  forgotPassword(email: string) {
    return this.commonService.post(this.forgotPasswordURL + email, {});
  }

  // Reset user password
  resetPassword(data: any) {
    return this.commonService.post(this.resetPasswordURL, data);
  }

  // Add or update user role
  AddUpdateRole(data: any) {
    return this.commonService.post(this.addUpdateRoleURL, data);
  }

  // Delete user role by ID
  deleteRole(id: string) {
    return this.commonService.deleteById(this.deleteRoleURL + id, {});
  }

  // Get user role by ID
  getRoleById(id: string) {
    return this.commonService.getById(this.getRoleByIdURL + id, {});
  }
}
