<!-- PAGE -->
<div class="page">
  <div>

    <!-- Theme-Layout -->

    <!-- CONTAINER OPEN -->
    <div class="col col-login mx-auto">
      <div class="text-center">
        <a href="index.html"><img src="../assets/images/brand/logo-white.png" class="header-brand-img m-0" alt=""></a>
      </div>
    </div>

    <!-- CONTAINER OPEN -->
    <div class="container-login100">
      <div class="wrap-login100 p-6">
        <form class="login100-form validate-form" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <span class="login100-form-title pb-5">
            Forgot Password
          </span>
          <p class="text-muted">Enter the email address registered on your account</p>
          <div class="wrap-input100 validate-input input-group" data-bs-validate="Valid email is required: ex@abc.xyz">
            <input class="input100 ms-0 form-control" type="email" placeholder="Email address" formControlName="email"
              appNoSpace>
            <span href="javascript:void(0)" class="input-group-text bg-white text-muted">
              <i class="zmdi zmdi-email" aria-hidden="true"></i>
            </span>
          </div>
          <div *ngIf="isSubmitted && error('email','required')" class="text-danger mb-1">
            Email address is required.
          </div>
          <div *ngIf="isSubmitted && error('email','pattern')" class="text-danger mb-1">
            Please enter valid email address.
          </div>
          <div class="submit">
            <button type="submit" class="btn btn-primary d-grid w-100">Send</button>
            <button type="submit" class="btn btn-cancel w-100 mt-1" (click)="goBack()">Cancel</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
<!--END PAGE -->
