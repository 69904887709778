<!-- password-change.component.html -->

<div class="row mt-5">
  <div class="col-xl-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Change Password</h3>
      </div>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Old Password <span class="star-red">*</span></label>
                <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                  <input [type]="showPassword['oldPassword'] ? 'password' : 'text'" class="input100 ms-0 form-control"
                    placeholder="Old Password" formControlName="oldPassword" appNoSpace>
                  <a href="javascript:void(0)" class="input-group-text bg-white text-muted"
                    (click)="togglePassword('oldPassword')">
                    <i class="zmdi " [ngClass]="showPassword['oldPassword'] ? 'zmdi-eye-off':'zmdi-eye'"
                      aria-hidden="true"></i>
                  </a>
                </div>
                <div *ngIf="isSubmitted && (error('oldPassword', 'required') || error('oldPassword', 'whitespace'))"
                  class="text-danger">
                  Old Password is required.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>New Password <span class="star-red">*</span></label>
                <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                  <input [type]="showPassword['newPassword'] ? 'password' : 'text'" class="input100 ms-0 form-control"
                    placeholder="New Password" formControlName="newPassword" appNoSpace>
                  <a href="javascript:void(0)" class="input-group-text bg-white text-muted"
                    (click)="togglePassword('newPassword')">
                    <i class="zmdi " [ngClass]="showPassword['newPassword'] ? 'zmdi-eye-off':'zmdi-eye'"
                      aria-hidden="true"></i>
                  </a>
                </div>
                <div *ngIf="isSubmitted && (error('newPassword', 'required') || error('newPassword', 'whitespace'))"
                  class="text-danger">
                  New Password is required.
                </div>
              
                <div *ngIf="isSubmitted && !error('newPassword', 'whitespace') && error('newPassword', 'pattern')"
                  class="text-danger">
                  Password must be at least 8 characters long and contain at least 1 lowercase alphabetical character, 1
                  uppercase alphabetical character, 1 special character, and 1 numeric character.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Confirm Password <span class="star-red">*</span></label>
                <div class="wrap-input100 validate-input input-group" id="Password-toggle">
                  <input [type]="showPassword['confirmPassword'] ? 'password' : 'text'" appNoSpace
                    class="input100 ms-0 form-control" placeholder="Confirm Password" formControlName="confirmPassword">
                  <a href="javascript:void(0)" class="input-group-text bg-white text-muted"
                    (click)="togglePassword('confirmPassword')">
                    <i class="zmdi " [ngClass]="showPassword['confirmPassword'] ? 'zmdi-eye-off':'zmdi-eye'"
                      aria-hidden="true"></i>
                  </a>
                </div>
                <div
                  *ngIf="isSubmitted && (error('confirmPassword', 'required') || error('confirmPassword', 'whitespace'))"
                  class="text-danger">
                  Confirm Password is required.
                </div>
                <div *ngIf="isSubmitted && error('confirmPassword', 'confirmedValidator')" class="text-danger">
                  Confirm Password must be the same as the New Password.
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer text-end">
            <button type="submit" class="btn btn-primary m-1">Change</button>
            <button type="button" class="btn btn-cancel my-1" (click)="reset()">Reset</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
