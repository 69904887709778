import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.css']
})
export class CommonFormComponent {
  formTitle:string;
  form:FormGroup;
  editMode:boolean;
  bsConfig: Partial<BsDatepickerConfig>;
  @Input() formData: any; 
  @Output() formSubmit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  fb = inject(FormBuilder);

  constructor() {
    this.form = this.fb.group({
      // Define form controls here
    });
  }

  onSubmit(){
    if (this.form.valid) {
      const formData = this.form.value;
      this.formSubmit.emit(formData);
    }
  }

  goBack(){

  }

  onFileChange(event:any){

  }




}
