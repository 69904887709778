import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(private http: HttpClient) { }

  login() {
    window.location.href = 'https://adfs.welspun.com/adfs/ls/idpinitiatedsignon?loginToRp=https://weldatahub.welspun.com';
  }

  // Handle SAML response from the server
  handleSamlResponse(response: string) {
    // Send the SAML response to the server for validation and JWT generation.
    return this.http.post('/api/sso/Login', { samlResponse: response });
  }

}
