import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuConstants } from '../staticmenu';

@Component({
  selector: 'app-sub-sidebar',
  templateUrl: './sub-sidebar.component.html',
  styleUrls: ['./sub-sidebar.component.css']
})
export class SubSidebarComponent implements OnInit {
  menu: any = [];
  submenu: any = [];
  toggleSlide: boolean = false;
  index: number;
  menuTitle = localStorage.getItem('title');
  getMenuTitle: any = localStorage.getItem('mainTitle');
  ProjectID: any = localStorage.getItem('projectID');

  constructor(private _router: Router) {
  }

  ngOnInit() {
    this.getModuleInfo();
  }

  // Navigate to a specific route
  navigateToRoute() {
    this._router.navigate(['/project/sub-sidebar/daily-plan']);
  }

  // Fetch module information based on the current route
  getModuleInfo() {
    const href = this._router.url;
    const array = href.split("/");
    this.menu = this.getMenuTitle == 'Transportation' ? MenuConstants.AllMenus : MenuConstants.waterMenus;
    if (array[1] == 'dashboard') {
      localStorage.removeItem('title')
      this.menuTitle = null;
    }
  }

  // Check if the current item has child items and toggle sliding
  hasChildItems(item: any, i: number) {
    this.toggleSlide = !this.toggleSlide
    if (this.toggleSlide || this.menuTitle != item.title) {
      this.submenu = item.menu
      localStorage.setItem('title', item.title);
      this.menuTitle = localStorage.getItem('title');
    } else {
      this.menuTitle = '';
    }
  }

  // Navigate to the dashboard view
  navigateToDashboard() {
    this._router.navigate([`/dashboard/view/${this.ProjectID}`]);
  }

}
