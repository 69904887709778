import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // Form group for the login form
  loginForm!: FormGroup;

  // Flag to track whether the form has been submitted
  isSubmitted: boolean = false;

  // Flag to control the visibility of the password
  showPassword: boolean = true;

  // Regular expressions for email and password validation
  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) { }

  // Function to check if a form control has a specific error
  public error = (control: string, error: string) => {
    return this.loginForm.controls[control].hasError(error);
  }

  ngOnInit() {
    // Initializing the login form with validation rules
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailregex)]],
      password: [null, [Validators.required, Validators.pattern(this.passwordRegex)]],
    });
  }

  // Function to toggle the visibility of the password
  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  // Dummy login function (replace with actual logic)
  login() {
    this.route.navigate(['/welcome-screen']);
  }

  // Function to handle form submission
  onSubmit() {
    this.isSubmitted = true;

    // Stop here if the form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Prepare data for login API
    let postData = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };

    // Call the login API
    this.accountService.login(postData)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if ((response.status == 'Success' && response.token) && response.message) {
            this.toastr.success(response.message);
            
            // Check if it's the first login, navigate to change password if true
            if (response.isFirstTime == true) {
              this.route.navigate(['/change-password']);
            } else {
              sessionStorage.setItem('redirectTo', '/welcome-screen');
              this.route.navigate(['/welcome-screen']);
            }
          } else {
            this.toastr.error(response.message);
          }
        },
        error => {
          this.toastr.error(error.message);
        });
  }
}
