import { Component, OnInit } from '@angular/core';
declare var Chart: any;


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  token: any = localStorage.getItem('access_token');
  userName = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];


  ngOnInit(){
    var transactionsCanvas = document.getElementById('transactions');
    var recentOrdersCanvas = document.getElementById('recentorders');

    // Create data for your charts
    var transactionsData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [{
        label: 'Daily Plan',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }, {
        label: 'Daily Achieved',
        data: [3, 5, 2, 12, 7, 6],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }]
    };

    var recentOrdersData = {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [{
        label: 'Recent Plan',
        data: [30, 45, 60, 20, 85, 40, 55],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };

    // Create and render the charts
    var transactionsChart = new Chart(transactionsCanvas, {
      type: 'bar',
      data: transactionsData,
      options: {
        responsive: true
      }
    });

    var recentOrdersChart = new Chart(recentOrdersCanvas, {
      type: 'bar',
      data: recentOrdersData,
      options: {
        responsive: true
      }
    });
  }


}
