import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/common/services/common.service';
import { AccountService } from 'src/app/services/account.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { MenuConstants } from '../staticmenu';

interface SubmenuItem {
  screenPermissionId: any;
  moduleId: any;
  roleId: any;
  screenId: any;
  screenName: any;
  screenKey: any;
  permission: any;
  displayOrder: any;
  navigationLink: any;
  type: any;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  private accountService = inject(AccountService);
  token: any = localStorage.getItem('access_token');
  userId = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];
  userInfo: any = [];
  role = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  getMenuTitle: any = localStorage.getItem('mainTitle');
  ProjectID: any = localStorage.getItem('projectID');
  RoleId: any = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  menu: any = [];
  submenu: any = [];
  menuTitle = localStorage.getItem('title');
  toggleSlide: boolean = false;
  esgMenu: any = [];
  isEmission: boolean = false;

  constructor(
    private route: Router,
    private rolePermissionService: RolePermissionService,
    private commonService: CommonService,
  ) {

    this.ProjectID = localStorage.getItem('projectID');
    // this.getModuleInfo();
    this.getUserRolePermissions();
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Handle the URL change here
        if (event.url.includes('/dashboard/view')) {
          this.ProjectID = localStorage.getItem('projectID');
          this.getModuleInfo();
          this.getUserRolePermissions();
        }
      }
    });
  }

  ngOnInit() {
    this.getByIdUser();
    this.ProjectID = localStorage.getItem('projectID');
    this.getModuleInfo();
    this.rolePermissionService.roleData$.subscribe((res) => {
      if (res == 'Success' || res == 'success') {
        this.ProjectID = localStorage.getItem('projectID');
        this.getModuleInfo();
      }
    })

  }

  // Logout function
  logout() {
    this.commonService.logout();
    this.route.navigate(['']);
  }

  changeTheme() {

  }

  // Refresh the component to reflect changes
  refreshComponent() {
    const currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }

  // Get module information based on the current URL
  getModuleInfo() {
    const href = this.route.url;
    const array = href.split("/");
    this.getMenuTitle = localStorage.getItem('mainTitle');
    if (this.getMenuTitle == 'Transportation') {
      this.menu = MenuConstants.AllMenus
    }
    if (this.getMenuTitle == 'Water') {
      this.menu = MenuConstants.AllMenus.filter((x: any) => {
        if (x.title == 'ESG') {
          return x
        }

      });
    }
    if (array[1] == 'dashboard') {
      localStorage.removeItem('title')
      this.menuTitle = null;
    }
  }

  // Check if an item has child items
  hasChildItems(item: any, i: number) {
    this.toggleSlide = !this.toggleSlide
    if (this.toggleSlide || this.menuTitle != item.title) {
      this.submenu = item.menu
      localStorage.setItem('title', item.title);
      this.menuTitle = localStorage.getItem('title');
    } else {
      this.menuTitle = '';
    }
  }

  // Handle mouseover event
  onMouseOver(item: any) {
    if (item) {
      this.esgMenu = item.menu;
    } else {
      this.esgMenu = [];
    }
    if (item.name == 'Emission') {
      this.isEmission = true
    } else {
      this.isEmission = false
    }

  }

  // Handle mouseout event
  onMouseOut() {
    this.esgMenu = [];
  }

  // Navigate to dashboard with the current project ID
  navigateToDashboard() {
    this.route.navigate([`/dashboard/view/${this.ProjectID}`]);
  }

  // Get user information by user ID
  getByIdUser() {
    this.accountService.getByIdUser(this.userId).subscribe((res: any) => {
      this.userInfo = res.data.user;
    })
  }

  // Get user role permissions
  getUserRolePermissions()  // get permission data after select any dropdown value
  {
    if (this.ProjectID) {
      this.rolePermissionService.getRolePermissions(this.RoleId, this.ProjectID).subscribe((response: any) => {
        this.commonService.setPermission(response.data);
        const outputArray = [];
        for (const item of response.data.modulePermissions) {
          if (item.permission) {
            const menuItem = {
              modulePermissionId: item.modulePermissionId,
              moduleId: item.moduleId,
              moduleName: item.moduleName,
              moduleKey: item.moduleKey,
              roleId: item.roleId,
              roleName: item.roleName,
              permission: item.permission,
              displayOrder: item.displayOrder,
              moduleIcon: item.moduleIcon,
              navigationLink: item.navigationLink,
              submenu: [] as SubmenuItem[], // Initialize submenu as an empty array
            };

            // Check if it's a module with associated screens
            // Find screens associated with the module
            const screens = response.data.screenPermissions.filter((screen: any) => screen.moduleId === item.moduleId);
            for (const screen of screens) {
              if (screen.permission) {
                var submenuItem: SubmenuItem = {
                  screenPermissionId: screen.screenPermissionId,
                  moduleId: screen.moduleId,
                  roleId: screen.roleId,
                  screenId: screen.screenId,
                  screenName: screen.screenName,
                  screenKey: screen.screenKey,
                  permission: screen.permission,
                  displayOrder: screen.displayOrder,
                  navigationLink: screen.navigationLink,
                  type: screen.type,
                }
                menuItem.submenu.push(submenuItem);
              }
            }
            outputArray.push(menuItem);
          }
        }
        this.menu = outputArray;
      });
    }
  }

  // Check if the submenu has emissions title
  showEmissionsTitle(submenu: any[]): boolean {
    let found = false;
    for (const subItem of submenu) {
      if (subItem.type === 'EMISSIONS') {
        found = true;
        break;
      }
    }
    return found;
  }

  // Check if the submenu has water title
  showWaterTitle(submenu: any[]): boolean {
    let found = false;
    for (const subItem of submenu) {
      if (subItem.type === 'WATER') {
        found = true;
        break;
      }
    }
    return found;
  }




}
