import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'src/app/common/services/common.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  // Form group for the forgot password form
  forgotPasswordForm: FormGroup;

  // Flag to track whether the form has been submitted
  isSubmitted = false;

  // Injecting services using Angular's inject function
  accountService = inject(AccountService);
  toastr = inject(ToastrService);

  constructor(private formBuilder: FormBuilder, private router: Router) {
    // Initializing the forgot password form with validation rules
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          noWhitespaceValidator,
          Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ]
      ],
    });
  }

  ngOnInit() {
    
  }

  // Function to check if a form control has a specific error
  public error = (control: string, error: string) => {
    return this.forgotPasswordForm.controls[control].hasError(error);
  }

  // Submit function for the forgot password form
  onSubmit() {
    this.isSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {
      this.accountService.forgotPassword(this.forgotPasswordForm.value.email)
        .subscribe((res) => {
          if (res.status == 'Success' || res.status == 'success') {
            this.toastr.success(res.message);
            this.goBack();
          } else {
            this.toastr.error(res.message);
          }
        }, (error) => {
          const errorLog = error.error;
          this.toastr.error(errorLog?.message);
        });
    }
  }

  // Function to navigate back to the login page
  goBack() {
    this.router.navigate(['login']);
  }

}
