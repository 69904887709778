<div class="row">
  <div class="col-12 col-sm-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title mb-0">{{tableTitle}}</h3>
        <button *ngIf="addPermission" routerLink="{{routing}}" [queryParams]="{ table: param }"
          class="btn btn-primary ms-auto">Add
          {{tableTitle}}</button>
      </div>
      <div class="card-body pt-4">
        <div class="grid-margin">
          <div class="">
            <div class="panel panel-primary">
              <div class="panel-body tabs-menu-body border-0 pt-0">
                <div class="d-flex align-items-center justify-content-start mb-3 me-3">
                  <input type="text" class="form-control w-25" placeholder="Search for results..." autocomplete="off"
                    (keyup)="onKeyup($event)" [(ngModel)]="search">
                  <button class="btn btn-primary ms-2" (click)="onSearch()">Search</button>
                  <button class="btn btn-cancel ms-1" (click)="onReset()" [disabled]="!search">Clear</button>
                </div>
                <div class="tab-content">
                  <div class="tab-pane active" id="tab5">
                    <!--  -->

                    <!-- / -->
                    <div class="table-responsive">
                      <table id="data-table" class="table table-bordered text-nowrap mb-0">
                        <thead class="border-top-0">
                          <tr class="table-border-top">
                            <th class="bg-transparent border-bottom-0" *ngFor="let item of columns">
                              {{item.displayName}}</th>
                            <th class="bg-transparent border-bottom-0">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border-bottom"
                            *ngFor="let item of data | paginate : { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems, id: tableTitle}">
                            <td *ngFor="let header of columns" class="truncate-cell">
                              <div class="mt-0 mt-sm-3 d-block">
                                <h6 class="mb-0 fs-14 fw-semibold text-truncate"
                                  [matTooltip]="item[header.key]?.length > 27 && columnSize > 4 ? item[header.key] : null">
                                  {{ header.key === 'date' || header.key === 'roadWorkDate' || header.key ===
                                  'structureWorkDate' ? (item[header.key] |
                                  date:'d MMM, y') : (item[header.key] |
                                  nullOrPlaceholder) }}
                                </h6>
                              </div>
                            </td>
                            <td>
                              <ng-container *ngFor="let actionTypes of actions">
                                <button class="btn text-primary btn-sm mt-2" data-bs-toggle="tooltip"
                                  [title]="actionTypes.key"
                                  (click)="onActionClick(actionTypes.key, item, actionTypes.tableName)"
                                  *ngIf="(actionTypes.key === 'attachment' && item.attachment) || actionTypes.key !== 'attachment'">
                                  <span [class]="actionTypes.class"></span>
                                </button>
                              </ng-container>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div *ngIf="data && !data.length" class="text-center no-record mt-2">No
                        record found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <ul class="pagination float-end">
                  <pagination-controls [id]="tableTitle" previousLabel="Prev" nextLabel="Next"
                    (pageChange)="onPaginationClick($event)">
                  </pagination-controls>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ROW-4 END -->