import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { noWhitespaceValidator } from 'src/app/common/services/common.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  // Flag to track whether the form has been submitted
  isSubmitted = false;

  // Form group for change password form
  changePasswordForm: FormGroup;

  // Extracting email from token
  token: any = localStorage.getItem('access_token');
  email = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"];

  // Object to track password visibility
  showPassword: any = {
    oldPassword: true,
    newPassword: true,
    confirmPassword: true
  };

  // Injecting services using Angular's inject function
  private accountService = inject(AccountService);
  private toastr = inject(ToastrService);

  constructor(private fb: FormBuilder, private route: Router) {
    // Initializing the change password form with validation rules
    this.changePasswordForm = this.fb.group({
      email: [this.email, [Validators.required]],
      oldPassword: ['', [Validators.required, noWhitespaceValidator]],
      newPassword: ['', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9\d$@$!%*?&].{7,}'),
        noWhitespaceValidator
      ]],
      confirmPassword: ['', [Validators.required, noWhitespaceValidator]],
    },
      {
        validators: [ConfirmedValidator('newPassword', 'confirmPassword')]
      });
  }

  // Function to check if a form control has a specific error
  public error = (control: string, error: string) => {
    return this.changePasswordForm.controls[control].hasError(error);
  }

  // Function to toggle password visibility
  togglePassword(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  // Submit function for the change password form
  onSubmit() {
    this.changePasswordForm.get('email')?.setValue(this.email);
    this.isSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    } else {
      this.accountService.changePassword(this.changePasswordForm.value)
        .subscribe((res) => {
          if (res.status == 'Success' || res.status == 'success') {
            this.toastr.success(res.message);
            this.refreshComponent();
          }
          else {
            this.toastr.error(res.message);
          }
        }, (error) => {
          const errorLog = error.error;
          this.toastr.error(errorLog?.message);
        })
    }
  }

  // Function to reset the form
  reset() {
    this.isSubmitted = false;
    this.changePasswordForm.reset();
  }

  // Function to refresh the component
  refreshComponent() {
    const currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
    });
  }
}

// Custom validator function to check if old and new passwords match
export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
