export class MenuConstants {

  public static projectMenu: any = [
    {
      "name": "Daily Progress Achieved",
      "url": "/daily-progress-achieved",
      "activeURL": "/project/daily-progress-achieved",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Achieved Monthly",
      "url": "/achieved-monthly",
      "activeURL": "/project/achieved-monthly",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    }
  ];

  public static projectPlanMenu: any = [
    {
      "name": "Annual Business Plan",
      "url": "/basline-plan-monthly",
      "activeURL": "/project/basline-plan-monthly",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Quarterly Forecast Plan",
      "url": "/quarterly-forecast-monthly",
      "activeURL": "/project/quarterly-forecast-monthly",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static brcMenu: any = [

    {
      "name": "Key Information",
      "url": "/key-information",
      "activeURL": "/brc/key-information",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Milstone Dates",
      "url": "/Milstone-dates",
      "activeURL": "/brc/milstone-dates",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Project Dates",
      "url": "/project-dates",
      "activeURL": "/brc/project-dates",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    }
  ];

  public static ncrMenu: any = [
    {
      "name": "Internal NCR",
      "url": "/internal-ncr",
      "activeURL": "/ncr/internal-ncr",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "External NCR",
      "url": "/external-ncr",
      "activeURL": "/ncr/external-ncr",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Quality Observations",
      "url": "/quality-observation-ncr",
      "activeURL": "/ncr/quality-observation-ncr",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static rfiMenu: any = [
    {
      "name": "Daily RFI Status",
      "url": "/daily-achieved",
      "activeURL": "/rfi/daily-achieved",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Plus Daily RFI",
      "url": "/daily-achieved",
      "activeURL": "/rfi/daily-achieved",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static emissionMenu: any = [
    {
      "name": "Fuel",
      "url": "/emissions",
      "activeURL": "/esg/emissions-fuel",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Electricity",
      "url": "/emissions",
      "activeURL": "/esg/emissions-electricity",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Construction",
      "url": "/emissions",
      "activeURL": "/esg/emissions-construction",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static safetyMenu: any = [
    {
      "name": "Over All",
      "url": "/over-all",
      "activeURL": "/safety/over-all",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Detailed Incidents",
      "url": "/detailed-incidents",
      "activeURL": "/safety/detailed-incidents",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Training",
      "url": "/training",
      "activeURL": "/safety/training",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Safety NCR",
      "url": "/safety-ncr",
      "activeURL": "/safety/safety-ncr",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static eventTrackerMenu: any = [
    {
      "name": "Schedule G Progress",
      "url": "/schedule-progress",
      "activeURL": "/brc/schedule-progress",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Bridgeworks",
      "url": "/bridge-works",
      "activeURL": "/brc/bridge-works",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Borrow Area",
      "url": "/borrow-area",
      "activeURL": "/brc/borrow-area",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Roadworks",
      "url": "/road-works",
      "activeURL": "/brc/road-works",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "LA",
      "url": "/la",
      "activeURL": "/brc/la",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "COS",
      "url": "/daily-achieved",
      "activeURL": "/brc/cos",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Pond Ash",
      "url": "/pond-ash",
      "activeURL": "/brc/pond-ash",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
    {
      "name": "Catch up Plan",
      "url": "/catchup-plan",
      "activeURL": "/brc/catchup-plan",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static emissionWaterMenu: any = [
    {
      "name": "Water",
      "url": "/water",
      "activeURL": "/esg/water",
      "icon": "icon-mrg side-menu__icon fe fe-home"
    },
  ];

  public static esgComplianceMenu: any = [];

  // Water Menus
  public static waterMenus: any = [

    {
      icon: "icon-mrg side-menu__icon fe fe-feather",
      name: 'Emission',
      menu: this.emissionMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-droplet",
      name: 'Water',
      menu: this.emissionWaterMenu
    },

  ]

  // All Menus
  public static AllMenus: any = [
    {
      icon: "icon-mrg side-menu__icon fe fe-file-text",
      title: 'Project Details',
      menu: this.brcMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-calendar",
      title: 'Project Plan',
      menu: this.projectPlanMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-calendar",
      // title: 'Progress Reports',
      title: 'Updates',
      menu: this.projectMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-inbox",
      title: 'RFI',
      menu: this.rfiMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-droplet",
      title: 'ESG',
      menu: this.waterMenus,
      subMenu: true
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-file-minus",
      title: 'Quality',
      menu: this.ncrMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-file-minus",
      title: 'Safety',
      menu: this.safetyMenu
    },
    {
      icon: "icon-mrg side-menu__icon fe fe-file-minus",
      title: 'Events Tracker',
      menu: this.eventTrackerMenu
    },

  ]

}

