import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any): string | null {
    if (value instanceof Date && !isNaN(value.getTime())) {
      // Value is already a valid Date object, format it
      const datePipe = new DatePipe('en-US');
      return datePipe.transform(value, 'd MMM, y');
    } else if (typeof value === 'string') {
      const parsedDate = Date.parse(value);
      if (!isNaN(parsedDate)) {
        // If Date.parse returns a valid timestamp, format it as a date
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(new Date(parsedDate), 'd MMM, y');
      }
    }
    // Return the original value if it's not a valid date
    return value;
  }
}
