import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectTypeService } from 'src/app/services/project-type.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';
import { MenuConstants } from '../staticmenu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  businessToggleSide = false;
  projectToggleSide = false;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  projects: any = [];
  private projectTypeService = inject(ProjectTypeService);
  menu: any;
  getMenuTitle: any = localStorage.getItem('mainTitle');
  transportationToggleSide: boolean = false;
  waterProjects: { projectID: number; projectName: string; icon: string; color: string; totalRecords: number; }[];
  waterToggleSide: boolean;
  token: any = localStorage.getItem('access_token');
  role = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
  userId = JSON.parse(window.atob(this.token.split('.')[1]))
  ["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"];

  constructor(private _router: Router, private rolePermissionService: RolePermissionService) { }

  ngOnInit() {
    this.getModuleInfo();
    this.onBusinessUnit(this.getMenuTitle);
    this.projectTypeService.projectData$.subscribe((res) => {
      if (res == 'Success' || res == 'success') {
        this.onBusinessUnit(this.getMenuTitle);
      }
    })
  }

  // Handle business unit selection
  onBusinessUnit(value: string) {
    localStorage.setItem('mainTitle', value);
    // this.getProjects()
    this.getProjectAssignList();
  }

  // Fetch the list of projects assigned to the user
  getProjectAssignList() {
    this.projectTypeService.getProjectAssignList(this.userId)
      .subscribe((res: any) => {
        this.projects = res.data;
      })
  }

  // In your component class
  hasTransportationProjects(): boolean {
    return this.projects.some((project: any) => project.type === 'Transportation');
  }

  // Check if there are water projects
  hasWaterProjects(): boolean {
    return this.projects.some((project: any) => project.type === 'Water');
  }

  // Navigate to a specific project
  navigate(project: any) {
    localStorage.setItem('projectID', project.projectID);
    localStorage.setItem('mainTitle', project.type);
    this._router.navigate([`/dashboard/view/${project.projectID}`]);
  }

  // Fetch the module information based on the current route
  getModuleInfo() {
    const href = this._router.url;
    const array = href.split("/");
    if (array[1] == "project") {
      this.menu = MenuConstants.projectMenu;
    }
    else if (array[1] == "brc") {
      this.menu = MenuConstants.brcMenu;
    }
    else if (array[1] == "ncr") {
      this.menu = MenuConstants.ncrMenu;
    }
    else if (array[1] == "rfi") {
      this.menu = MenuConstants.rfiMenu;
    }
  }

  // Toggle the sidebar for transportation or water units
  onToggle(value: string) {
    if (value === 'Transportation') {
      this.transportationToggleSide = !this.transportationToggleSide
    } else if (value === 'Waters') {
      this.waterToggleSide = !this.waterToggleSide
    }
  }
}
