import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectTypeService {
  private getProjectTypes = 'api/Project/GetProjectList';
  private addUpdateProjectData = 'api/Project/AddUpdateProject';
  private getProjectByProjectId = 'api/Project/GetProjectById';
  private deleteProjectById = 'api/Project/DeleteProjectById';
  private getProjectAssignListURL = 'api/Project/GetProjectAssignList';
  private commonService = inject(CommonService);
  private projectDataSubject = new BehaviorSubject<any>(null);
  projectData$ = this.projectDataSubject.asObservable();

  setProjectData(data: any) {
    this.projectDataSubject.next(data);
  }

  getProject(PageNumber: number, PageSize: number, SearchText: string) {
    // Initialize the URL with the base part
    let url = `${this.getProjectTypes}?PageNumber=${PageNumber}&PageSize=${PageSize}`;
    // Check if SearchText is provided and not empty
    if (SearchText) {
      // Encode the SearchText to handle special characters properly
      const encodedSearchText = encodeURIComponent(SearchText);
      url += `&SearchText=${encodedSearchText}`;
    }
    return this.commonService.getAll(url, {});
    // return this.commonService.getAll(this.getProjectTypes + '?PageNumber=' + PageNumber + '&PageSize=' + PageSize, {});
  }

  getProjectAssignList(UserId: any) {
    return this.commonService.getAll(this.getProjectAssignListURL + '?UserId=' + UserId, {});
  }



  addUpdateProject(projectInfoPayload: any) {
    return this.commonService.post(this.addUpdateProjectData, projectInfoPayload);
  }

  getProjectById(projectId: number) {
    return this.commonService.getById(this.getProjectByProjectId + '?projectId=' + projectId, {});
  }

  deleteProjectByID(projectId: number) {
    return this.commonService.deleteById(this.deleteProjectById + '?projectId=' + projectId, {});
  }
  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    return this.commonService.getUserScreenActionPermissions(moduleName, screenName);
  }
}

