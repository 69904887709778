import { Injectable } from '@angular/core';
import { CommonService } from '../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private getUserByRoleId = 'api/Account/GetUserList';
  private addUserData = 'api/Account/Register';
  private updateUserData = 'api/Account/UpdateUser';
  private getUserByUserId = 'api/Account/GetByIdUser';
  private deleteUserById = 'api/Account/DeleteUser';
  private getRoles = 'api/Account/GetRoleList';

  constructor(private commonService: CommonService) { }

  getUsers(PageNumber: number, PageSize: number, SearchText: string) {
    // Initialize the URL with the base part
    let url = `${this.getUserByRoleId}?PageNumber=${PageNumber}&PageSize=${PageSize}`;
    // Check if SearchText is provided and not empty
    if (SearchText) {
      // Encode the SearchText to handle special characters properly
      const encodedSearchText = encodeURIComponent(SearchText);
      url += `&SearchText=${encodedSearchText}`;
    }
    return this.commonService.getAll(url, {});
  }

  addUser(UserPayload: any) {
    return this.commonService.post(this.addUserData, UserPayload);
  }

  updateUser(UserPayload: any) {
    return this.commonService.put(this.updateUserData, UserPayload);
  }

  getUserById(userId: string) {
    return this.commonService.getById(this.getUserByUserId + '?userId=' + userId, {});
  }

  deleteUserByID(userId: string) {
    return this.commonService.deleteById(this.deleteUserById + '?userId=' + userId, {});
  }

  getUserRoles() {
    return this.commonService.getAll(this.getRoles, {});
  }
  getUserScreenActionPermissions(moduleName: string, screenName: string): any {
    return this.commonService.getUserScreenActionPermissions(moduleName, screenName);
  }
}
