<!-- GLOBAL-LOADER -->
<!-- <div id="global-loader">
  <img src="../assets/images/loader.svg" class="loader-img" alt="Loader">
</div> -->
<!-- /GLOBAL-LOADER -->
<!-- PAGE -->
<div class="page">
  <div class="page-main">

    <!-- APP-HEADER -->
    <app-header></app-header>
    <!-- /APP-HEADER -->
    <!--APP-SIDEBAR-->
    <app-sidebar></app-sidebar>
    <!--/APP-SIDEBAR-->
    <div class="jumps-prevent" style="padding-top: 74px;"></div>
    <!-- MAIN-CONTENT -->
    <div class="main-content app-content mt-0">
      <div class="side-app">
        <!-- CONTAINER -->
        <div class="main-container">
          <router-outlet></router-outlet>
        </div>
        <!-- CONTAINER END -->
      </div>
    </div>
    <!-- MAIN-CONTENT CLOSED -->
  </div>
  <!-- FOOTER -->
  <app-footer></app-footer>
  <!-- FOOTER END -->
</div>
